import React, { useState, useEffect } from "react";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { useSelector } from "react-redux";
// import MUIDataTable from "mui-datatables";
import Loading from "./Loading";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { checkRole } from "../model";
import { Table } from "react-bootstrap";
import Moment from "react-moment";
import FastLogin from "./FastLogin";
import PartsOfMember from "./PartsOfMember";
import ResetPass from "./ResetPass";
import Pagination from "react-js-pagination";

function SRList(props) {
  const parsed = window.location.search.replace('?', '');
  let { children, idrole, name, linkaction, defaultLimit, upload, params, columns, onlyList, noAction, moresearch, refresh, TopBar,listvalue } = props;
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [totalResult, setTotalResult] = useState(0);
  const limit = defaultLimit ? defaultLimit : 10;
  const [totalRecord, setTotalRecord] = useState(defaultLimit ? defaultLimit : 10);
  const [change, setChange] = useState(false);
  const allowAdd = checkRole(user, idrole, 'add');
  const allowEdit = checkRole(user, idrole, 'edit');
  const allowDelete = checkRole(user, idrole, 'delete');
  const allowUpload = checkRole(user, idrole, 'upload');
  const allowView = checkRole(user, idrole, 'view');
  const allowAccept = checkRole(user, idrole, 'approve');
  const allowExport = checkRole(user, idrole, 'export');
  const allowReopen = checkRole(user, idrole, 'reopen');

  // const allowRoles = checkRole(user, idrole, 'roles');
  //console.log(parsed)
  useEffect(
    function () { 
      let mounted = true;
      if (mounted) {
        setRows([]);
        setLoading(true)
      }
      let config = {
        headers: {
          'Authorization': 'Bearer ' + user.access_token,
          'Content-Type': 'application/json; charset=UTF-8',
          'search': JSON.stringify(moresearch)
        }
      }
      Axios.get(URL_LIST_ALL + name + "?&page=" + page + "&limit=" + limit + (params ? '&' + params : '') + (parsed ? '&' + parsed : ''), config)
        .then((res) => {
          setLoading(false)
          if (res.data.status == "success") {
              setRows(res.data.data);
              setTotalResult(res.data.total)
              if(listvalue) listvalue(res.data)
              setTotalRecord(res.data.total);
          } else console.log(res.data.message);
        })
        .catch((err) => {
          console.log(err);
          
          if (mounted) setLoading(false)
        });
      return () => (mounted = false);
    },
    [name, change, user, page, limit, params, parsed, moresearch, refresh]
  );

  const actionDelete = (id) => {
    confirmAlert({
      title: "Thông báo",
      message: "Bạn có chắc muốn xoá dữ liệu",
      buttons: [
        {
          label: "Có",
          onClick: () => {
            Axios.delete(URL_LIST_ALL + name + '/' + id, user.config).then(res => {
              if (res.data.status === "success") {
                setChange(!change);
              } else alert(res.data.message);
            }).catch(err => console.log(err));
          },
        },
        { label: "Không" },
      ],
    });
  }

  const actionAprove = (id, data) => {

    confirmAlert({
      title: "Thông báo",
      message: "Bạn muốn phê duyệt ?",
      buttons: [{
        label: "Có",
        onClick: () => {
          setLoading(true)
          Axios.put(URL_LIST_ALL + name + '/' + id, data, user.config).then(res => {
            if (res.data.status === "success") {
              setChange(!change);
            } else alert(res.data.message);
            setLoading(false)
          }).catch(err => { console.log(err); setLoading(false) });
        },
      }, { label: "Không" },
      ],
    });
  }
  const actionReopen = (id, data) => {
    confirmAlert({
      title: "Thông báo",
      message: "Bạn muốn mở lại giải trình ?",
      buttons: [{
        label: "Có",
        onClick: () => {
          setLoading(true)
          data.re_open = 1
          Axios.put(URL_LIST_ALL + name + '/' + id, data, user.config).then(res => {
            if (res.data.status === "success") {
              setChange(!change);
            } else alert(res.data.message);
            setLoading(false)
          }).catch(err => { console.log(err); setLoading(false) });
        },
      }, { label: "Không" },
      ],
    });
  }

  const chotlaicong = (code) => {
    console.log(code);
    confirmAlert({
      title: "Thông báo",
      message: "Bạn muốn chốt lại công cho nhân sự này ? ",
      buttons: [{
        label: "Có",
        onClick: () => {
          setLoading(true)
          Axios.get(URL_LIST_ALL + 'chotlaicong?code=' + code, user.config).then(res => {
            setLoading(false)
            if (res.data.status === "success") {
              setChange(!change);
            } else alert(res.data.message);
          }).catch(err => { console.log(err); setLoading(false) });
        },
      }, { label: "Không" },
      ],
    });
  }
  const molaicong = (code) => {
    console.log(code);
    confirmAlert({
      title: "Thông báo",
      message: "Bạn muốn mở lại các bản đăng ký/giải trình công cho nhân sự này ?",
      buttons: [{
        label: "Có",
        onClick: () => {
          setLoading(true)
          Axios.get(URL_LIST_ALL + 'molaicong?code=' + code, user.config).then(res => {
            setLoading(false)
            if (res.data.status === "success") {
              setChange(!change);
            } else alert(res.data.message);
          }).catch(err => { console.log(err); setLoading(false) });
        },
      }, { label: "Không" },
      ],
    });
  }

  const chamCong = (code, datecheck) => {
    confirmAlert({
      title: "Thông báo",
      message: "Bạn muốn chấm công lại cho nhân sự này ?",
      buttons: [{
        label: "Có",
        onClick: () => {
          setLoading(false)
          window.open('https://apihr.bhsgroup.vn/cham-lai-cong?refresh=1&code=' + code + '&date=' + datecheck, '_blank');

        },
      }, { label: "Không" },
      ],
    });
  }

  return (
    <>
    {totalResult ? "Tìm được tổng: " +totalResult + " dữ liệu" : ""}
      {loading && (<Loading />)}
      {!onlyList && <div className="d-flex justify-content-end mb-3">
        {TopBar && <>{TopBar}</>}
        {allowExport &&
          <Link
            to={"/export-" + (linkaction ? linkaction : name)}
            className="btn btn-info m-2"
            style={{ fontSize: 13, fontWeight: '600', color: "#FFF" }}>
            <i className="fas fa-plus-"></i> Xuất file
          </Link>}
        {!noAction && allowAdd &&
          <Link
            to={"/add-" + (linkaction ? linkaction : name)}
            className="btn btn-success m-2"
            style={{ fontSize: 13, fontWeight: '600' }}>
            <i className="fas fa-plus-"></i> Thêm mới
          </Link>}
        {!upload && allowUpload &&
          <Link
            to={"/upload-" + (linkaction ? linkaction : name)}
            className="btn btn-info m-2"
            style={{ fontSize: 13, fontWeight: '600', color: "#FFF" }}>
            <i className="fas fa-plus-"></i> Upload
          </Link>}
      </div>}
      {children}

      {columns && columns.length > 0 &&
        <>
          <Table striped bordered hover className="d-none d-sm-table">
            <thead>
              <tr>
                {columns.map((item, i) => {
                  return <th key={i}>{item.label}</th>
                })}
                {(allowDelete || allowEdit || allowView || allowAccept) && <td>Thao tác</td>}
                {name === 'baocaochamcong' &&
                  <th>#</th>
                }
              </tr>
            </thead>
            <tbody>
              {rows && rows.length > 0 && rows.map((itemTr, iTr) => {
                // let idrow = itemTr.id;
                return (
                  <tr key={iTr}>

                    {columns.map((itemTd, iTd) => {
                      let result = itemTr[itemTd.name]

                      if (itemTd.options && itemTd.options.customBodyRender) {
                        const renderedValue = itemTd.options.customBodyRender(itemTr[itemTd.name], itemTr);
                        result = (renderedValue);
                      } else {


                        if (itemTd.name === 'datecreate' || itemTd.name === 'datemodified' || itemTd.name === 'datesend') {
                          if (itemTr[itemTd.name]) {
                            let time = new Date(itemTr[itemTd.name] * 1000);
                            result = <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>
                          }

                        }
                        if (itemTd.type && itemTd.type === 'fastlogin') {
                          result = <FastLogin id={itemTr[itemTd.name]} />;
                        }
                        if (itemTd.name === 'partofmember') {
                          result = <PartsOfMember id={itemTr[itemTd.name]} />;
                        }
                        if (itemTd.type && itemTd.type === 'resetpass') {
                          result = <ResetPass id={itemTr[itemTd.name]} />;
                        }

                        if (name === 'users_onday' && itemTd.name === 'status') {
                          result = itemTr[itemTd.name] === 1 ? "Đúng giờ" : "Muộn";
                        }
                        if (itemTd.name === 'status') {
                          if (name == 'users') {
                            if (itemTr[itemTd.name] === 0) result = <span className="text-danger">Đã Nghỉ</span>
                            if (itemTr[itemTd.name] === 1) result = <span className="text-success">Đang chính thức</span>
                            if (itemTr[itemTd.name] === 2) result = <span className="text-warning">Đang thử việc</span>
                            if (itemTr[itemTd.name] === 3) result = <span className="text-danger">Chưa nhận việc</span>
                            if (itemTr[itemTd.name] === 4) result = <span className="text-warning">Thực tập sinh</span>
                            if (itemTr[itemTd.name] === 5) result = <span className="text-warning">Cộng tác viên</span>
                          } else {
                            result = itemTr[itemTd.name] === 1 ? "Hoạt động" : "Ngưng hoạt động";
                          }

                        }
                        if (itemTd.name === 'checkin_status') {
                          if (itemTr[itemTd.name] === 0) result = <span className="text-danger">Chưa chấm vào</span>
                          if (itemTr[itemTd.name] === 1) result = <span className="text-success">Đúng giờ</span>
                          if (itemTr[itemTd.name] === 2) result = <span className="text-warning">Vào muộn</span>
                          if (itemTr[itemTd.name] === 3) result = <span className="text-success">Có phép</span>
                          if (itemTr[itemTd.name] === 4) result = <span className="text-success">Đã giải trình</span>
                        }
                        if (itemTd.name === 'checkout_status') {
                          if (itemTr[itemTd.name] === 0) result = <span className="text-danger">Chưa chấm ra</span>
                          if (itemTr[itemTd.name] === 1) result = <span className="text-success">Đúng giờ</span>
                          if (itemTr[itemTd.name] === 2) result = <span className="text-warning">Về sớm</span>
                          if (itemTr[itemTd.name] === 3) result = <span className="text-success">Có phép</span>
                          if (itemTr[itemTd.name] === 4) result = <span className="text-success">Đã giải trình</span>
                        }

                        if ((name === 'users_break' || name === 'giaitrinh' || name === 'duyet_giaitrinh' || name === 'duyet_xinnghi' || name === 'duyet_lamle' || name === 'working_holiday') && itemTd.name === 'status') {
                          if (itemTr[itemTd.name] === 0) {
                            result = <>
                              <span className="text-warning">Chờ duyệt</span><br />
                              {allowAccept && <button className="btn btn-sm btn-success" onClick={() => actionAprove(itemTr.id, { status: 1 })}>Phê duyệt</button>}<br />
                              {allowAccept && <button className="btn btn-sm btn-danger mt-1" onClick={() => actionAprove(itemTr.id, { status: 2 })}>Từ chối</button>}
                            </>
                          }
                          if (itemTr[itemTd.name] === 1) result = <span className="text-success">Đã duyệt</span>
                          if (itemTr[itemTd.name] === 2) result = <span className="text-danger">Từ chối duyệt</span>
                          if (itemTr[itemTd.name] === 3) result = <span className="text-info">Đang xem xét</span>
                          if (itemTr[itemTd.name] === 4) result = <span className="text-info">Chờ gửi</span>
                          if (itemTr[itemTd.name] === 5) result = <div><span className="text-danger">Hết hạn gửi</span> {allowReopen && <button className="btn btn-sm btn-danger mt-1" onClick={() => actionReopen(itemTr.id, { status: 4 })}>Mở lại</button>}</div>
                          if (itemTr[itemTd.name] === 6) result = <div><span className="text-danger">Hết hạn phê duyệt</span>{allowReopen && <button className="btn btn-sm btn-danger mt-1" onClick={() => actionReopen(itemTr.id, { status: 0 })}>Mở lại</button>}</div>
                        }

                        if (name === 'vanthu' && itemTd.name === 'status') {
                          if (itemTr[itemTd.name] === 0) result = <span className="text-warning">Chờ duyệt</span>
                          if (itemTr[itemTd.name] === 1) result = <span className="text-info">Quản lý đã duyệt, chờ cấp số</span>
                          if (itemTr[itemTd.name] === 2) result = <span className="text-info">Đã cấp số chờ gửi file Scan</span>
                          if (itemTr[itemTd.name] === 3) result = <span className="text-danger">Đã gửi file chờ xác nhận</span>
                          if (itemTr[itemTd.name] === 4) result = <span className="text-success">Thành công</span>
                        }

                        if (name === 'sendmail' && itemTd.name === 'status') {
                          if (itemTr[itemTd.name] === 0) result = <span className="text-warning">Chưa gửi</span>
                          if (itemTr[itemTd.name] === 1) result = <span className="text-success">Đã gửi</span>
                          if (itemTr[itemTd.name] === 2) result = <span className="text-danger">Gửi thất bại</span>
                        }
                        if (name === 'calam' && itemTd.name === 'type') {
                          if (itemTr[itemTd.name] === 1) result = <span className="text-success">Ca sáng</span>
                          if (itemTr[itemTd.name] === 2) result = <span className="text-danger">Ca chiều</span>
                        }

                        if (itemTd.name === 'datestart' || itemTd.name === 'date_start' || itemTd.name === 'dateend' || itemTd.name === 'date_end' || itemTd.name === 'date_check' || itemTd.name === 'datecheck') {
                          if (itemTr[itemTd.name]) {
                            let time = new Date(itemTr[itemTd.name]);
                            result = <Moment format="DD/MM/YYYY">{time}</Moment>
                          }
                        }
                      }

                      return <td key={iTd}>{result}</td>
                    })}

                    {(allowEdit || allowDelete || allowView || allowAccept) &&
                      <td>
                        {allowEdit && <Link to={"/edit-" + (linkaction ? linkaction : name) + "/" + itemTr.id} className="btn btn-sm btn-warning m-1" data-toggle="tooltip" data-placement="bottom" title="Sửa"><i className="fas fa-pencil-alt"></i></Link>}
                        {allowDelete && <button className="btn btn-sm btn-danger m-1" onClick={() => actionDelete(itemTr.id)} data-toggle="tooltip" data-placement="bottom" title="Xóa"><i className="fas fa-trash-alt"></i></button>}
                        {allowView && name !== 'users_onday' && <Link to={"/view-" + (linkaction ? linkaction : name) + "/" + itemTr.id} className="btn btn-sm btn-warning m-1" data-toggle="tooltip" data-placement="bottom" title="Xem"><i className="fas fa-eye"></i></Link>}
                        {allowAccept && <Link to={"/approve-" + (linkaction ? linkaction : name) + "/" + itemTr.id} className="btn btn-sm btn-success m-1" data-toggle="tooltip" data-placement="bottom" title="Phê duyệt">Xem</Link>}
                        {allowEdit && name === "users_onday" && <button className="btn btn-sm btn-info m-1 text-white" onClick={() => chamCong(itemTr.code, itemTr.datecheck)} data-toggle="tooltip" data-placement="bottom" title="Chấm công lại"><i className="fab fa-gg-circle"></i></button>}
                      </td>
                    }
                    {name === 'baocaochamcong' &&
                      <td>
                        <button className="btn btn-sm btn-success w-100 mt-1" onClick={() => { chotlaicong(itemTr.code) }} data-toggle="tooltip" data-placement="bottom" title="Chốt lại bảng chấm công"><i className="fas fa-exchange-alt"></i> Chốt lại</button>
                        <button className="btn btn-sm btn-warning w-100 mt-1" onClick={() => { molaicong(itemTr.code) }} data-toggle="tooltip" data-placement="bottom" title="Mở lại bảng chấm công"><i className="fas fa-exchange-alt"></i> Mở lại</button>
                      </td>
                    }
                  </tr>
                )
              })
              }
            </tbody>
          </Table>
          <div className="d-block d-sm-none table-mobile">
            <div className="w-100 list">
              {rows && rows.length > 0 && rows.map((itemTr, iTr) => {
                // let idrow = itemTr.id;
                return (
                  <div className="list-item shadow" key={iTr}>

                    {columns.map((itemTd, iTd) => {
                      let result = itemTr[itemTd.name]
                      if (itemTd.options && itemTd.options.customBodyRender) {
                        const renderedValue = itemTd.options.customBodyRender(itemTr[itemTd.name], itemTr);
                        result = (renderedValue);
                      } else {
                        if (itemTd.name === 'datecreate' || itemTd.name === 'datemodified' || itemTd.name === 'datesend') {
                          if (itemTr[itemTd.name]) {
                            let time = new Date(itemTr[itemTd.name] * 1000);
                            result = <Moment format="HH:mm DD/MM/YYYY">{time}</Moment>
                          }
                        }
                        if (itemTd.type && itemTd.type === 'fastlogin') {
                          result = <FastLogin id={itemTr[itemTd.name]} />;
                        }
                        if (itemTd.name === 'partofmember') {
                          result = <PartsOfMember id={itemTr[itemTd.name]} />;
                        }
                        if (itemTd.type && itemTd.type === 'resetpass') {
                          result = <ResetPass id={itemTr[itemTd.name]} />;
                        }

                        if (name === 'users_onday' && itemTd.name === 'status') {
                          result = itemTr[itemTd.name] === 1 ? "Đúng giờ" : "Muộn";
                        }
                        if (itemTd.name === 'status') {
                          if (name == 'users') {
                            if (itemTr[itemTd.name] === 0) result = <span className="text-danger">Đã Nghỉ</span>
                            if (itemTr[itemTd.name] === 1) result = <span className="text-success">Đang chính thức</span>
                            if (itemTr[itemTd.name] === 2) result = <span className="text-warning">Đang thử việc</span>
                            if (itemTr[itemTd.name] === 3) result = <span className="text-danger">Chưa nhận việc</span>
                            if (itemTr[itemTd.name] === 4) result = <span className="text-warning">Thực tập sinh</span>
                            if (itemTr[itemTd.name] === 5) result = <span className="text-warning">Cộng tác viên</span>
                          } else {
                            result = itemTr[itemTd.name] === 1 ? "Hoạt động" : "Ngưng hoạt động";
                          }

                        }
                        if (itemTd.name === 'checkin_status') {
                          if (itemTr[itemTd.name] === 0) result = <span className="text-danger">Chưa chấm vào</span>
                          if (itemTr[itemTd.name] === 1) result = <span className="text-success">Đúng giờ</span>
                          if (itemTr[itemTd.name] === 2) result = <span className="text-warning">Vào muộn</span>
                          if (itemTr[itemTd.name] === 3) result = <span className="text-success">Có phép</span>
                          if (itemTr[itemTd.name] === 4) result = <span className="text-success">Đã giải trình</span>
                        }
                        if (itemTd.name === 'checkout_status') {
                          if (itemTr[itemTd.name] === 0) result = <span className="text-danger">Chưa chấm ra</span>
                          if (itemTr[itemTd.name] === 1) result = <span className="text-success">Đúng giờ</span>
                          if (itemTr[itemTd.name] === 2) result = <span className="text-warning">Về sớm</span>
                          if (itemTr[itemTd.name] === 3) result = <span className="text-success">Có phép</span>
                          if (itemTr[itemTd.name] === 4) result = <span className="text-success">Đã giải trình</span>
                        }

                        if ((name === 'users_break' || name === 'giaitrinh' || name === 'duyet_giaitrinh' || name === 'duyet_xinnghi' || name === 'duyet_lamle' || name === 'working_holiday') && itemTd.name === 'status') {
                          if (itemTr[itemTd.name] === 0) {
                            result = <>
                              <span className="text-warning">Chờ duyệt</span><br />
                              {allowAccept && <button className="btn btn-sm btn-success" onClick={() => actionAprove(itemTr.id, { status: 1 })}>Phê duyệt</button>}<br />
                              {allowAccept && <button className="btn btn-sm btn-danger mt-1" onClick={() => actionAprove(itemTr.id, { status: 2 })}>Từ chối</button>}
                            </>
                          }
                          if (itemTr[itemTd.name] === 1) result = <span className="text-success">Đã duyệt</span>
                          if (itemTr[itemTd.name] === 2) result = <span className="text-danger">Từ chối duyệt</span>
                          if (itemTr[itemTd.name] === 3) result = <span className="text-info">Đang xem xét</span>
                          if (itemTr[itemTd.name] === 4) result = <span className="text-info">Chờ gửi</span>
                          if (itemTr[itemTd.name] === 5) result = <div><span className="text-danger">Hết hạn gửi</span> {allowReopen && <button className="btn btn-sm btn-danger mt-1" onClick={() => actionReopen(itemTr.id, { status: 4 })}>Mở lại</button>}</div>
                          if (itemTr[itemTd.name] === 6) result = <div><span className="text-danger">Hết hạn phê duyệt</span>{allowReopen && <button className="btn btn-sm btn-danger mt-1" onClick={() => actionReopen(itemTr.id, { status: 0 })}>Mở lại</button>}</div>
                        }

                        if (name === 'sendmail' && itemTd.name === 'status') {
                          if (itemTr[itemTd.name] === 0) result = <span className="text-warning">Chưa gửi</span>
                          if (itemTr[itemTd.name] === 1) result = <span className="text-success">Đã gửi</span>
                          if (itemTr[itemTd.name] === 2) result = <span className="text-danger">Gửi thất bại</span>
                        }
                        if (name === 'calam' && itemTd.name === 'type') {
                          if (itemTr[itemTd.name] === 1) result = <span className="text-success">Ca sáng</span>
                          if (itemTr[itemTd.name] === 2) result = <span className="text-danger">Ca chiều</span>
                        }

                        if ((name === 'giaitrinh' || name === 'duyet_giaitrinh') && itemTd.name === 'reason') {
                          if (itemTr.images) {
                            result = <div className="w-100" ><span>{itemTr[itemTd.name]}</span> <img src={itemTr.images} className='w-100' alt='' /></div>
                          } else {
                            result = itemTr[itemTd.name]
                          }
                        }

                        if (itemTd.name === 'datestart' || itemTd.name === 'date_start' || itemTd.name === 'dateend' || itemTd.name === 'date_end' || itemTd.name === 'date_check' || itemTd.name === 'datecheck') {
                          if (itemTr[itemTd.name]) {
                            let time = new Date(itemTr[itemTd.name]);
                            result = <Moment format="DD/MM/YYYY">{time}</Moment>
                          }
                        }
                      }

                      return <div className="w-100 d-row d-flex" key={iTd}>
                        <div className="item-left" >{itemTd.label}</div>
                        <div className="item-right">{result}</div>
                      </div>
                    })}

                    {(allowEdit || allowDelete || allowView || allowAccept) &&
                      <div className="w-100 d-row d-flex justify-content-center">
                        {allowEdit && <Link to={"/edit-" + (linkaction ? linkaction : name) + "/" + itemTr.id} className="btn btn-sm btn-warning m-1" data-toggle="tooltip" data-placement="bottom" title="Sửa"><i className="fas fa-pencil-alt"></i> Sửa</Link>}
                        {allowDelete && <button className="btn btn-sm btn-danger m-1" onClick={() => actionDelete(itemTr.id)} data-toggle="tooltip" data-placement="bottom" title="Xóa"><i className="fas fa-trash-alt"></i> Xóa</button>}
                        {allowView && name !== 'users_onday' && <Link to={"/view-" + (linkaction ? linkaction : name) + "/" + itemTr.id} className="btn btn-sm btn-warning m-1" data-toggle="tooltip" data-placement="bottom" title="Xem"><i className="fas fa-eye"></i> Xem</Link>}
                        {allowAccept && <Link to={"/approve-" + (linkaction ? linkaction : name) + "/" + itemTr.id} className="btn btn-sm btn-success m-1" data-toggle="tooltip" data-placement="bottom" title="Phê duyệt"> Phê duyệt</Link>}
                        {allowEdit && name === "users_onday" && <button className="btn btn-sm btn-info m-1 text-white" onClick={() => chamCong(itemTr.code, itemTr.datecheck)} data-toggle="tooltip" data-placement="bottom" title="Chấm công lại"><i className="fab fa-gg-circle"></i> Chấm lại</button>}
                      </div>
                    }
                    {name === 'baocaochamcong' &&
                      <div>
                        <button className="btn btn-sm btn-success w-100 mt-1" onClick={() => { chotlaicong(itemTr.code) }} data-toggle="tooltip" data-placement="bottom" title="Chốt lại bảng chấm công"><i className="fas fa-exchange-alt"></i> Chốt lại</button>
                        <button className="btn btn-sm btn-warning w-100 mt-1" onClick={() => { molaicong(itemTr.code) }} data-toggle="tooltip" data-placement="bottom" title="Mở lại bảng chấm công"><i className="fas fa-exchange-alt"></i> Mở lại</button>
                      </div>
                    }
                  </div>
                )
              })
              }
            </div>
          </div>
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={totalRecord}
            pageRangeDisplayed={5}
            itemClass="page-item"
            linkClass="page-link"
            prevPageText="<"
            nextPageText=">"
            onChange={setPage}
          />
        </>
      }
    </>
  );
}
export default React.memo(SRList)
//https://github.com/gregnb/mui-datatables